<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }} (v.{{ version }})
      <b-link class="ml-25" :href="companyUrl" target="_blank">{{
        appName
      }}</b-link>
      <span class="d-none d-sm-inline-block ml-25">
        {{ $t('AllrightsReserved') }}
      </span>
    </span>

    <span class="float-md-right d-none d-md-block">Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
      {{ author }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
  },
  setup() {
    // App Name
    const { appName, companyUrl, author, version } = $themeConfig.webConfigs[
      document.location.hostname
    ]
      ? $themeConfig.webConfigs[document.location.hostname]
      : $themeConfig.app
    return {
      pagename: 'dashboard',
      appName,
      author,
      version,
      companyUrl,
    }
  },
}
</script>
