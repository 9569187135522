export default [
  {
    header: 'Menu_Cases',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Menu_Dashboards',
    icon: 'MonitorIcon',
    route: 'dashboard',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Menu_RegionSetting',
    icon: 'DatabaseIcon',
    route: 'casesetting',
    resource: 'User',
    action: 'read',
  },
]
