export default [
  {
    header: 'Menu_Other',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Logout',
    route: 'Logout',
    resource: 'User',
    action: 'read',
    icon: 'LogOutIcon',
  },
]
